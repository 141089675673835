import React from "react";
import "../css/Footer.css";
import logo from "../assets/it accurate logo.png";

const Footer = () => {
  return (
    <div className="footer">
      <footer>
        <div className="container">
          <div className="footer-box logo-main">
            <img src={logo} alt="" />
            <p>TRAINING AND PLACEMENT CENTER..</p>

            <div className="social-icons">
              <a
                href="https://www.facebook.com/ITAccurate"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://x.com/ITAccurate2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com/itaccurate?igsh=MWxsOHhmenV4cjhnZQ%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
          <div className="footer-box">
            <h3>Explore</h3>
            <ul>
  <li><a href="/about">About Us</a></li>
  {/* <li><a href="/services">Services</a></li> */}
  {/* <li><a href="/courses">Courses</a></li> */}
  <li><a href="/blog">Blog</a></li>
  <li><a href="/contact">Contact Us</a></li>
</ul>

          </div>
          <div className="footer-box">
            <h3>Quick Links</h3>
            <ul>
              <li><a href="/contact">Contact Us</a></li>
              {/* <li>Pricing</li> */}
              <li><a href="/terms">Terms & Conditions</a></li>
              <li><a href="/privacy">Privacy Policy</a></li>
              <li><a href="/refund">Refund Policy</a></li>
            </ul>
          </div>
          <div id="unique" className="footer-box">
    <h3>Have a Question?</h3>
    <ul>
    <li>
            <i className="fa fa-map-marker-alt"></i> <strong>Thane Office:</strong><br></br> Office No. 806, 8th Floor, Paradise Tower, next to McDonald's, Naupada, Thane West, Mumbai, Thane, Maharashtra 400601      Ph. +91-7738277389
        </li>
       
        <hr style={{ width: "100%", margin: "10px auto", border: "0.5px solid #ccc" }} />
        <li>
            <i className="fa fa-map-marker-alt"></i> <strong>Nagpur Office:</strong>607, 608 B-wing, Lokmat Bhavan, Lokmat Square, Ramdaspeth, Nagpur, Maharashtra 440012          Ph. +91-9175978889 
        </li>
        <hr style={{ width: "100%", margin: "10px auto", border: "0.5px solid #ccc" }} />
        <li>
            <i className="fa fa-phone-alt"></i> +91-7738277389
        </li>
        <li>
            <i className="fa fa-envelope"></i> info@itaccurate.com
        </li>
    </ul>
</div>

        </div>
      </footer>
      <div className="legal">
        <p>Powered by Sapalogy | &copy; 2024 All rights reserved</p>
      </div>
    </div>
  );
};

export default Footer;
