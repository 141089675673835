import React, { useEffect, useRef } from "react";
import "../css/DevOps.css";
import certificateImg from "../assets/Certificates/IT accurate certificate.png";
import Roadmap from "../components/Roadmap";
import WhatsDevOps from "../components/WhatsDevOps";
import Syllabus from "../components/Syllabus";
import KeyFeatures from "../components/KeyFeatures";
import Faq from "../components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Marq from '../components/CustomMarquee';
import JobPreparation from '../components/JobPreparation';
import BatchesPage from "../components/Batches";
import SalaryHikeSection from '../components/SalaryHike';
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../components/ContactForm";
import MarqueeLogos from "../components/MarqueeLogos";
import Learn from '../components/Learn';
import CourseOpportunities from '../components/CourseOpportunities'
import StickyNavbar from "../components/StickyNavbar";

const AIML = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // Function to create the YouTube player
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(videoRef.current, {
          videoId: "AGALIxFkB94", // Updated YouTube video ID
          events: {
            onReady: (event) => {
              // event.target.mute(); // Mute the video to allow autoplay
              event.target.playVideo();
            },
          },
          playerVars: {
            loop: 1,
            controls: 0, // Hide video controls
            modestbranding: 1, // Minimal YouTube branding
            playlist: "AGALIxFkB94", // Required for looping
          },
        });
      }
    };

    // Load YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up callback for when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }
  }, []);

  const roadmapData = [
    {
      title: "Introduction",
      points: [
        "What is AI & ML?",
        "AI & ML course",
        "AI & ML certification",
        "AI & ML jobs in India",
        "AI & ML jobs in Thane",
      ],
    },
    {
      title: "Foundational Skills",
      points: [
        "Math: Linear algebra, calculus, probability & statistics are crucial for understanding algorithms and analyzing data.",
        "Programming: Python is the go-to language. Learn about data structures, algorithms, and object-oriented programming. Explore libraries like NumPy, Pandas, Matplotlib.",
        "Databases: Get familiar with SQL for querying and manipulating relational databases.",
      ],
    },
    {
      title: "Data Science Core",
      points: [
        "Machine Learning: Supervised (regression, classification) and unsupervised (clustering, dimensionality reduction) learning algorithms.",
        "Statistics & Hypothesis testing: Understand statistical testing, p-values, and confidence intervals.",
        "Data Wrangling & Cleaning: Learn data manipulation techniques with Pandas and data cleaning practices.",
        "Data Visualization: Effectively communicate insights using libraries like Matplotlib, Seaborn, Tableau.",
      ],
    },
    {
      title: "Deepening the Knowledge",
      points: [
        "Advanced ML Algorithms: Ensemble methods, boosting, time series analysis, deep learning (neural networks, CNNs).",
        "Domain Knowledge: Choose a specific domain (finance, healthcare, etc.) to gain industry-specific knowledge and problem-solving skills.",
        "Big Data Technologies: Explore Apache Spark, Hadoop for handling large datasets.",
        "Cloud Computing: Familiarize yourself with cloud platforms like AWS, Azure, Google Cloud Platform for data storage and computing.",
      ],
    },
    {
      title: "Specialization & Projects",
      points: [
        "Specialization: Pick a niche area (e.g., NLP, computer vision, recommender systems) and dive deeper.",
        "Portfolio Building: Work on real-world projects to showcase your skills and understanding. Contribute to open-source projects.",
        "Communication & Collaboration: Hone your communication skills to explain complex technical concepts effectively. Learn to collaborate effectively in teams.",
      ],
    },
    {
      title: " Feedback Loop and Iteration",
      points: [
        "Establish a feedback loop for continuous improvement based on user feedback and evolving business needs.",
        "Iteratively update models and algorithms to adapt to changing conditions.",
      ],
    },
    {
      title: "Cost Analysis and ROI Measurement",
      points: [
        "Conduct cost analysis for AI and ML implementation, including infrastructure, training, and maintenance.",
        "Measure the return on investment (ROI) based on improved efficiency, cost savings, and other business metrics.",
      ],
    },
    {
      title: "Resume Preparation",
      points: [
        "Include keywords.",
        "How to prepare resume.",
        "How to prepare resume for freshers.",
        "Show impact.",
        "Include soft skills.",
        "Be unique.",
      ],
    },
  ];

  const syllabus = [
    {
      title: "Introduction to Python",
      subpoints: [
        "Python Basics",
        "Python Functions and Packages",
        "Working with Data Structures, Arrays,Vectors & Data Frames",
        "Google colab notebook",
        "Pandas, NumPy, Matplotlib, Seaborn",
      ],
    },
    {
      title: "Applied Statistics",
      subpoints: [
        "Descriptive Statistics",
        "Probability & Conditional Probability",
        "Hypothesis Testing",
        "Inferential Statistics",
        "Probability Distributions",
      ],
    },
    {
      title: "EDA and Data Processing",
      subpoints: [
        "Data Types",
        "Dispersion & Skewness",
        "Uni & Multivariate Analysis",
        "Data Imputation",
        "Identifying and Normalizing Outlier",
      ],
    },
    {
      title: "Introduction to Neural Networks and Deep Learning",
      subpoints: [
        "Introduction to Perceptron & Neural Networks",
        "Activation and Loss functions",
        "Gradient Descent",
        "Batch Normalization",
        "TensorFlow & Keras for Neural Networks",
        "Hyper Parameter Tuning",
      ],
    },
    {
      title: "Computer Vision",
      subpoints: [
        "Introduction to Convolutional Neural Networks",
        "Introduction to Images",
        "Convolution, Pooling, Padding & its Mechanisms",
        "Forward Propagation & Backpropagation for CNNs",
        "CNN architectures like AlexNet, VGGNet, InceptionNet & ResNet",
        "Transfer Learning",
        "Object Detection",
        "YOLO, R-CNN, SSD",
        "Semantic Segmentation",
        "U-Net",
        "Face Recognition using Siamese Networks",
        "Instance Segmentation",
        "3 PROJECTS ON CV",
      ],
    },
    {
      title: "NLP (Natural Language Processing)",
      subpoints: [
        "Introduction to NLP",
        "Stop Words.",
        "Tokenization",
        "Stemming and Lemmatization",
        "Bag of Words Model",
        "Word Vectorizer",
        "TF-IDF",
        "POS Tagging",
        "Named Entity Recognition",
        "Introduction to Sequential data",
        "RNNs and its Mechanisms",
        "Vanishing & Exploding gradients in RNNs",
        "LSTMs – Long short-term memory",
        "GRUs – Gated Recurrent Unit",
        "LSTMs Applications",
        "Time Series Analysis",
        "LSTMs with Attention Mechanism",
        "Neural Machine Translation",
        "Advanced Language Models:",
        "Transformers, BERT, XLNet",
        "3 PROJECTS ON NLP",
      ],
    },
    {
      title: "Introduction to Reinforcement Learning (RL)",
      subpoints: [
        "RL Framework",
        "Component of RL Framework",
        "Examples of RL Systems",
        "Types of RL Systems",
        "Q-learning",
      ],
    },
    {
      title: "Introduction to GANS (Generative Adversarial Networks)",
      subpoints: [
        "Introduction to GANS",
        "Generative Networks",
        "Adversarial Networks",
        "How do GANs work?",
        "DCGANS – Deep Convolution GANs",
        "Applications of GANS",
      ],
    },
    {
      title: "ADDITIONAL MODULES",
      subpoints: [
        "EDA",
        "Time Series Forecasting",
        "Pre Work for Deep Learning",
        "Model Deployment",
        "Visualization using Tensor board",
        "GANS (Generative Adversarial Networks)",
        "Reinforcement Learning",
        "Recommendation Systems",
      ],
    },
    {
      title: "Chat GPT and Open Al Timeline of NLP and Generative Al",
      subpoints: [
        "Frameworks for Understanding Chat GPT and Generative Al",
        "Implications for Work, Business, and Education Output",
        "Modalities and Limitations",
        "Business Roles to Leverage Chat GPT Prompt Engineering for Fine-Tuning Outputs",
        "Practical Demonstration and Bonus Section on RLHF",
        "Introduction to Generative Al",
        "Al vs ML",
        "DL vs Gen Al",
        "Supervised vs Unsupervised Learning.",
        "Discriminative vs Generative Al",
        "A Brief Timeline of Gen Al Basics of Generative Models",
        "Large Language Models Word Vectors",
        "Chat GPT: The Development Stack Attention Mechanism",
        "Business Applications of ML, DL and Gen Al Hands-on Bing Images and Chat GPT",
      ],
    },
    {
      title: "Introduction to generative AI & Prompt engineering",
      subpoints: [
        "Mathematical Fundamentals for Generative AI",
        "VAES: First Generative Neural Networks",
        "GANS: Photorealistic Image Generation",
        "Conditional GANs and Stable Diffusion",
        "Transformer Models: Generative Al for Natural Language",
        "Chat GPT: Conversational Generative Al",
        "Hands-On Chat GPT Prototype Creation",
        "Next Steps for Further Learning and Understanding",
        "3 PROJECTS ON Chat GPT and Prompt Engineering (1 Week)",
      ],
    },
    {
      title: "Profit center accounting",
      subpoints: [
        "Understanding profit center and its use",
        "Profit center hierarchy & master data",
        "Profit center derivation & document splitting",
      ],
    },
    {
      title: "In Addition",
      subpoints: [
        "5+ projects on sap",
        "20+ topic wise tests",
        "Resume building",
        "Interview preparation",
        "6 month internship with cin no.",
        "Job opportunities",
        "One on one classroom interview practice",
      ],
    },
  ];

  const keyFeatures = [
    { text: "Limited Students Batch", icon: "fa-users" },
    { text: "Personalised Attention", icon: "fa-user-check" },
    { text: "Highly Qualified Teachers", icon: "fa-chalkboard-teacher" },
    { text: "Flexible Batch Timings", icon: "fa-calendar-alt" },
    { text: "Interactive Learning", icon: "fa-comments" },
    { text: "Live Projects", icon: "fa-laptop-code" },
    { text: "Career Support", icon: "fa-briefcase" },
    { text: "Job Oriented Training", icon: "fa-graduation-cap" },
  ];
  const faqs = [
    {
      question: "What's AI?",
      answer: "AI, or Artificial Intelligence, refers to the development of computer systems that can perform tasks that typically require human intelligence, such as speech recognition, decision-making, and learning.",
    },
    {
      question: "What is Machine Learning (ML)?",
      answer: "ML is a subset of AI that focuses on developing algorithms and models that enable computers to learn from data and improve their performance over time without being explicitly programmed.",
    },
    {
      question: "How does Machine Learning work?",
      answer: "ML systems learn patterns from data by using algorithms that adjust parameters to minimize errors. Common techniques include supervised learning (using labeled data) and unsupervised learning (finding patterns in unlabeled data).",
    },
    {
      question: "Difference between AI and ML?",
      answer: "AI is a broader concept, while ML is a subset of AI. AI encompasses any technique that allows computers to mimic human intelligence, whereas ML specifically involves learning from data.",
    },
    {
      question: "What is Deep Learning?",
      answer: "Deep Learning is a subset of ML that uses neural networks with multiple layers (deep neural networks) to model and solve complex problems, achieving high-level abstractions in data representation.",
    },
    {
      question: "Common Machine Learning Algorithms?",
      answer: "Some popular ML algorithms include Linear Regression, Decision Trees, Random Forest, Support Vector Machines, K-Nearest Neighbors, and Neural Networks.",
    },
    {
      question: "Supervised vs. Unsupervised Learning?",
      answer: "In supervised learning, the algorithm is trained on labeled data, while unsupervised learning involves finding patterns in unlabeled data without predefined outputs.",
    },
    {
      question: "Natural Language Processing (NLP) in AI?",
      answer: "NLP focuses on enabling computers to understand, interpret, and generate human language. Applications include language translation, sentiment analysis, and chatbots.",
    },
    {
      question: "Reinforcement Learning?",
      answer: "Reinforcement Learning involves training models to make sequences of decisions by receiving feedback in the form of rewards or penalties, commonly used in gaming, robotics, and autonomous systems.",
    },
    {
      question: "Bias in AI?",
      answer: "Bias in AI refers to the unfair and disproportionate impact of algorithms on certain groups. It can occur due to biased training data, algorithm design, or unintended consequences during implementation.",
    },
    {
      question: "Ethical concerns in AI?",
      answer: "Ethical concerns in AI include issues related to bias, transparency, accountability, job displacement, and privacy. Ensuring ethical AI development and use is crucial for responsible implementation.",
    },
    {
      question: "Future trends in AI and ML?",
      answer: "Emerging trends include Explainable AI, Federated Learning, AI-driven automation, AI in healthcare, and the continued advancement of reinforcement learning and natural language processing.",
    },
  ];

  const whatsData = [
    {
      title: 'What is AI & ML?',
      points: [
        'AI (Artificial Intelligence) is a field of computer science focused on creating machines that can perform tasks that typically require human intelligence.',
        'ML (Machine Learning) is a part of AI that involves training algorithms to detect patterns from data and make informed decisions or predictions.',
        'ML algorithms enhance their performance over time by learning from past experiences and adapting to new data.',
        'Supervised learning involves training algorithms on labeled data, while unsupervised learning explores patterns in unlabeled data.',
        'AI and ML are used in a wide range of industries, such as healthcare, finance, and robotics, and are driving innovation and automation.',
      ]
    }
  ];


  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="devops-page">
      <div className="content-container-devops">
        <div className="devops-circle devops-circle-bottom-left"></div>

        {/* Left Content */}
        <div className="left-content">
        <h1>IT Accurate - Best AI&ML Training in Mumbai, Thane & Navi Mumbai - Fees, Placements</h1>
          <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.8)</span>
            <span className="separator">|</span>
            <span className="ratings-count">5489 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">6367 Learners</span>
          </div>
          <p>
            The Best AI & ML Training by IT Accurate offers top-notch AI & ML training in Thane for both freshers and experienced candidates, with expert guidance and 100% placement assistance. Our AI & ML training program covers all the essential topics in detail, and students gain practical, real-world experience.
          </p>
          <p>
            Our hands-on, job-focused training program not only provides you with a certificate but also equips you with knowledge equivalent to 2+ years of field experience. We value your time as much as our own, which is why we aim to train you in AI & ML in just 7 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview preparation</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-month internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job placement guarantee</li>
          </ul>
        </div>


        {/* Right Side - Contact Form */}
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>

      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div>
      <StickyNavbar name="AIML" />
      <WhatsDevOps whatsData={whatsData} />
      <SalaryHikeSection />
      <BatchesPage Batches={BatchesPage} />
      <Learn course="aiMl" />
      <Roadmap heading="Roadmap to Learn AI & ML" roadmapData={roadmapData} />
      <JobPreparation />
      <KeyFeatures heading="AI & ML Training" keyFeatures={keyFeatures} />
      <Syllabus heading="AI & ML Training Syllabus" Syllabus={syllabus} />
      {/* <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for DevOps Training</h2>
        <p>
          Sapalogy provides flexible timings to all our students. Here is the DevOps Training Class Schedule in our branches. If this schedule doesn’t match your availability, please let us know. We will try to arrange appropriate timings based on your flexible timings.
        </p>

        <div className="batch-schedule-table-wrapper">
          <table className="batch-schedule-table">
            <thead>
              <tr>
                <th>Course</th>
                <th>Batch Time</th>
                <th>Offline</th>
                <th>Online</th>
                <th>Enquire Now</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>DevOps</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
              <tr>
                <td>AWS</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}

      <section  id="certification" className="devops-certification-section">
        <h2 className="certification-heading">AI & ML Certification</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>IT Accurate training certification serves as proof that the courses were completed at IT Accurate.</li>
              <li>The AI & ML certification offered by IT Accurate will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>IT Accurate provides comprehensive guidance for your AI & ML global certification, ensuring a 100% passing guarantee in examinations such as AI & ML Certification, AI & ML Platform Development Certification, and various other global exams.</li>
            </ul>


          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">Devops Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}
      <Marq />
      <CourseOpportunities pageName="AIML" />
      <Faq Faqs={faqs} />
      <MarqueeLogos />
    </div>
  );
};

export default AIML;
