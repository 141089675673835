import { useEffect, useState } from "react";
import "../css/StickyNavbar.css";

// StickyNavbar component accepts 'name' prop
const StickyNavbar = ({ name }) => {
  const [activeSection, setActiveSection] = useState("");

  const sections = [
    { id: "#whats", label: `What is ${name}?` },
    { id: "#roadmap", label: "Roadmap" },
    { id: "#key-features", label: "Key Features" },
    { id: "#syllabus", label: "Syllabus" },
    { id: "#certification", label: "Certification" },
    { id: "#faq", label: "FAQ" },
    { id: "placed", label: "Placement" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 100; // Adjust offset as needed
      sections.forEach(({ id }) => {
        const sectionElement = document.getElementById(id);
        if (sectionElement) {
          const { offsetTop, offsetHeight } = sectionElement;
          if (
            scrollPosition >= offsetTop &&
            scrollPosition < offsetTop + offsetHeight
          ) {
            setActiveSection(id);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []); // Effect only runs once, since there are no dependencies

  return (
    <div className="sticky-navbar">
      {sections.map(({ id, label }) => (
        <a
          key={id}
          href={`${id}`}
          className={activeSection === id ? "active" : ""}
        >
          {label}
        </a>
      ))}
    </div>
  );
};

export default StickyNavbar;
