import React, { useState, useRef } from "react";
import placedStudentsData from "../Placed";
import PlacedSlider from "../components/PlacedSlider";
import SubscribeDemo from "../components/SubscribeDemo";

const PlacedStudents = () => {
    const [showAll, setShowAll] = useState(false); // State to control table rows visibility
    const tableRef = useRef(null); // Reference for the table container

    const displayedStudents = showAll ? placedStudentsData : placedStudentsData.slice(0, 30);

    const toggleShow = () => {
        setShowAll(!showAll);
    };

    return (
        <div id="placement" className="min-h-screen bg-white mt-[4rem] text-black font-sans">
            <header className="p-6 text-start bg-white shadow-lg max-w-7xl mx-auto">
                <h1 className="text-3xl font-bold text-gray-800">
                    Here is a list of students who have successfully secured placements after completing their training at IT-Accurate
                </h1>
                <p className="mt-4 text-lg text-gray-700">
                    In today’s competitive IT industry, holding just a degree is no longer sufficient. With an increasing number of graduates and a limited number of job opportunities, candidates must stay ahead by mastering the latest technologies and tools utilized by top companies. At IT-Accurate, we go beyond traditional education by providing practical, hands-on training to help students bridge the gap between theoretical knowledge and real-world experience. Our courses are crafted to meet the demands of modern workplaces, equipping students with technical expertise and the confidence to thrive in their careers.
                </p>
            </header>

            <section className="p-6">
                <h2 className="text-2xl font-semibold mb-4">Placed Students By IT-Accurate</h2>
                <div className="relative overflow-hidden bg-white border border-gray-300 shadow-md rounded-lg">
                    <div
                        className="overflow-y-auto"
                        ref={tableRef}
                        style={{
                            maxHeight: "400px", // Table container height
                        }}
                    >
                        <table className="w-full border-collapse border border-gray-300">
                            <thead className="bg-gradient-to-r from-sky-300 to-white">
                                <tr>
                                    <th className="border border-gray-300 p-2">Sr. No</th>
                                    <th className="border border-gray-300 p-2">Photo</th>
                                    <th className="border border-gray-300 p-2">Name</th>
                                    <th className="border border-gray-300 p-2">Company</th>
                                    <th className="border border-gray-300 p-2">Role</th>
                                    <th className="border border-gray-300 p-2">Skills</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedStudents.map((student, index) => (
                                    <tr
                                        key={index}
                                        className="hover:bg-gray-100 transition duration-300 ease-in-out"
                                    >
                                        <td className="border border-gray-300 p-2 text-center">{index + 1}</td>
                                        <td className="border border-gray-300 p-2 text-center">
                                            <img
                                                src={student.photo || "https://via.placeholder.com/50"} // Default placeholder if no photo
                                                alt={student.name}
                                                className="w-10 h-10 rounded-full mx-auto"
                                                style={{
                                                    pointerEvents: "none", // Prevent pointer events like right-click
                                                }}
                                                onContextMenu={(e) => e.preventDefault()} // Disable right-click menu
                                                draggable="false" // Prevent image drag
                                            />
                                        </td>
                                        <td className="border border-gray-300 p-2">{student.name}</td>
                                        <td className="border border-gray-300 p-2">{student.company}</td>
                                        <td className="border border-gray-300 p-2">{student.role}</td>
                                        <td className="border border-gray-300 p-2">{student.skills.join(", ")}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Fixed Buttons */}
                    <div className="absolute inset-x-0 bottom-0 bg-white shadow-lg p-4 flex justify-center">
                        <button
                            onClick={toggleShow}
                            className="bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600 transition duration-300"
                        >
                            {showAll ? "Show Less" : "Show More"}
                        </button>
                    </div>
                </div>
            </section>

            {/* Other Sections */}
            <section className="p-6">
                <div className="flex flex-col md:flex-row gap-6">
                    <div className="flex-1 bg-white border border-gray-300 rounded-lg shadow-md p-4">
                        <h3 className="text-2xl font-bold mb-4 text-center">Alternative Placement Types</h3>
                        <ul className="list-none space-y-4">
                            {[
                                { category: "Non-IT to IT (Career Transition)", count: "2161+" },
                                { category: "Engineering Students", count: "3471+" },
                                { category: "Career Break / Gap Students", count: "2508+" },
                                { category: "Non-Engineering Students (Arts & Science)", count: "3119+" },
                                { category: "Diploma Candidates", count: "3021+" },
                                { category: "CTC Greater than 5 LPA", count: "3542+" },
                                { category: "Academic Percentage Less than 60%", count: "5283+" },
                            ].map((item, index) => (
                                <li
                                    key={index}
                                    className="relative p-4 border border-gray-200 rounded-md hover:bg-blue-500 hover:text-white hover:font-bold transition-all transform hover:scale-105"
                                >
                                    <div className="flex justify-between items-center">
                                        <span className="group-hover:text-white">{item.category}</span>
                                        <span className="font-extrabold group-hover:text-white">
                                            {item.count}
                                        </span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="flex-1">
                        <PlacedSlider />
                    </div>
                </div>
            </section>

            <SubscribeDemo />
        </div>
    );
};

export default PlacedStudents;
