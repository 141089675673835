import React, { useEffect, useRef } from "react";
import "../css/DevOps.css";
import certificateImg from "../assets/Certificates/IT accurate certificate.png";
import Roadmap from "../components/Roadmap";
import WhatsDevOps from "../components/WhatsDevOps";
import Syllabus from "../components/Syllabus";
import KeyFeatures from "../components/KeyFeatures";
import Faq from "../components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Learn from '../components/Learn';
import Marq from '../components/CustomMarquee';
import JobPreparation from '../components/JobPreparation';
import BatchesPage from "../components/Batches";
import SalaryHikeSection from '../components/SalaryHike';
import StickyNavbar from "../components/StickyNavbar";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../components/ContactForm";
import MarqueeLogos from "../components/MarqueeLogos";
import CourseOpportunities from '../components/CourseOpportunities'

const DevOpsPage = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  //   useEffect(() => {
  //     // Function to create the YouTube player
  //     const createPlayer = () => {
  //       if (window.YT && window.YT.Player) {
  //         playerRef.current = new window.YT.Player(videoRef.current, {
  //           videoId: "i3HdVH6gdfE", // Updated YouTube video ID
  //           events: {
  //             onReady: (event) => {
  //               // event.target.mute(); // Mute the video to allow autoplay
  //               event.target.playVideo();
  //             },
  //           },
  //           playerVars: {
  //             loop: 1,
  //             controls: 0, // Hide video controls
  //             modestbranding: 1, // Minimal YouTube branding
  //             playlist: "i3HdVH6gdfE", // Required for looping
  //           },
  //         });
  //       }
  //     };

  //     // Load YouTube IFrame API if it's not already loaded
  //     if (!window.YT) {
  //       const tag = document.createElement("script");
  //       tag.src = "https://www.youtube.com/iframe_api";
  //       const firstScriptTag = document.getElementsByTagName("script")[0];
  //       firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //       // Set up callback for when the API is ready
  //       window.onYouTubeIframeAPIReady = () => {
  //         createPlayer();
  //       };
  //     } else {
  //       createPlayer();
  //     }
  //   }, []);

  const roadmapData = [
    {
      title: "Introduction to ServiceNow",
      points: [
        "What is ServiceNow?",
        "ServiceNow course overview",
        "ServiceNow certification",
        "ServiceNow jobs in India",
        "ServiceNow jobs in Thane",
      ],
    },
    {
      title: "Getting Started with ServiceNow",
      points: [
        "Understand the ServiceNow platform and its modules.",
        "Learn the basics of ServiceNow navigation and user interface.",
        "Explore ServiceNow's key capabilities: ITSM, ITOM, and ITBM.",
        "Understand the ServiceNow product suite and licensing.",
      ],
    },
    {
      title: "ServiceNow Configuration and Customization",
      points: [
        "Configure applications and modules in ServiceNow.",
        "Understand and implement ServiceNow workflows.",
        "Customize forms, fields, and UI policies.",
        "Learn to create and manage ServiceNow catalog items.",
      ],
    },
    {
      title: "ServiceNow Development",
      points: [
        "Introduction to ServiceNow scripting (Client and Server-side).",
        "Learn ServiceNow API and Glide scripting.",
        "Develop custom applications on the ServiceNow platform.",
        "Implement security roles and access controls.",
      ],
    },
    {
      title: "Integration and Automation",
      points: [
        "Integrate ServiceNow with external systems.",
        "Utilize ServiceNow's Integration Hub and REST API.",
        "Automate tasks using Flow Designer and Workflow Editor.",
        "Implement ITOM for automated incident management.",
      ],
    },
    {
      title: "ServiceNow Administration",
      points: [
        "Set up and manage user accounts and permissions.",
        "Manage ServiceNow instances and instance upgrades.",
        "Monitor system performance and resolve issues.",
        "Maintain data integrity and cleanup processes.",
      ],
    },
    {
      title: "ServiceNow Reporting and Analytics",
      points: [
        "Generate and customize reports in ServiceNow.",
        "Implement performance analytics for data-driven insights.",
        "Set up dashboards to monitor key metrics.",
        "Analyze trends to drive business decisions.",
      ],
    },
    {
      title: "Resume Preparation for ServiceNow",
      points: [
        "Include ServiceNow-specific keywords and tools.",
        "Highlight ServiceNow certifications and hands-on experience.",
        "Demonstrate impact through project descriptions.",
        "Include both technical and soft skills.",
        "Tailor the resume for ServiceNow job roles.",
      ],
    },
  ];


  const syllabus = [
    {
      title: "Introduction to ServiceNow",
      subpoints: [
        "What is ServiceNow?",
        "Overview of ServiceNow platform",
        "ServiceNow Architecture",
        "Understanding IT Service Management (ITSM)",
        "ServiceNow Market Trends and Job Opportunities",
      ],
    },
    {
      title: "ServiceNow Navigation and User Interface",
      subpoints: [
        "Navigating the ServiceNow Instance",
        "Understanding the ServiceNow Homepage",
        "Using Application Navigator and Modules",
        "Working with Lists, Forms, and Filters",
        "Customizing User Interface for Specific Roles",
      ],
    },
    {
      title: "ServiceNow Administration",
      subpoints: [
        "Overview of System Administration",
        "User and Group Management",
        "Roles and Role-Based Access Control",
        "Configuring Notifications and Email Settings",
        "ServiceNow Instance Security",
      ],
    },
    {
      title: "ServiceNow ITSM Modules",
      subpoints: [
        "Incident Management",
        "Problem Management",
        "Change Management",
        "Knowledge Management",
        "Service Catalog and Request Management",
      ],
    },
    {
      title: "ServiceNow Development Basics",
      subpoints: [
        "Introduction to ServiceNow Scripting",
        "Client-side Scripting (Client Scripts, UI Policies)",
        "Server-side Scripting (Business Rules, Script Includes)",
        "Working with Scripted REST APIs",
        "Debugging and Troubleshooting Scripts",
      ],
    },
    {
      title: "ServiceNow Workflow and Flow Designer",
      subpoints: [
        "Introduction to Workflow in ServiceNow",
        "Creating and Managing Workflows",
        "Using Flow Designer for Automation",
        "Trigger Conditions and Actions",
        "Integrating Flow Designer with Other Modules",
      ],
    },
    {
      title: "ServiceNow Integration",
      subpoints: [
        "Introduction to Integration in ServiceNow",
        "Using IntegrationHub",
        "REST API and SOAP API Integration",
        "Import Sets and Data Transformation",
        "Integration with Third-Party Tools",
      ],
    },
    {
      title: "ServiceNow Reporting and Dashboards",
      subpoints: [
        "Creating Reports in ServiceNow",
        "Using Performance Analytics",
        "Building Dashboards and Widgets",
        "Exporting and Sharing Reports",
        "Real-Time Monitoring with Dashboards",
      ],
    },
    {
      title: "ServiceNow Configuration Management Database (CMDB)",
      subpoints: [
        "Introduction to CMDB",
        "Creating and Managing Configuration Items (CIs)",
        "CMDB Relationships and Dependency Views",
        "Automating CMDB Updates",
        "Integrating CMDB with ITSM Processes",
      ],
    },
    {
      title: "ServiceNow IT Operations Management (ITOM)",
      subpoints: [
        "Event Management",
        "Operational Intelligence",
        "Discovery and Service Mapping",
        "Cloud Management",
        "Orchestration in ITOM",
      ],
    },
    {
      title: "ServiceNow IT Business Management (ITBM)",
      subpoints: [
        "Introduction to ITBM",
        "Project and Portfolio Management (PPM)",
        "Resource Management",
        "Demand Management",
        "Agile and Test Management",
      ],
    },
    {
      title: "ServiceNow Performance Analytics",
      subpoints: [
        "Introduction to Performance Analytics",
        "Setting up KPIs and Metrics",
        "Building Analytics Dashboards",
        "Tracking and Improving Performance",
        "Advanced Analytics Use Cases",
      ],
    },
    {
      title: "ServiceNow Custom Applications",
      subpoints: [
        "Introduction to App Engine",
        "Creating Custom Applications",
        "Using Studio IDE",
        "Building and Deploying Applications",
        "Advanced Application Development Topics",
      ],
    },
    {
      title: "ServiceNow Security Operations (SecOps)",
      subpoints: [
        "Overview of Security Operations",
        "Threat Intelligence and Vulnerability Management",
        "Security Incident Management",
        "Orchestration for Incident Response",
        "SecOps Reporting and Dashboards",
      ],
    },
    {
      title: "ServiceNow Certification Preparation",
      subpoints: [
        "Overview of ServiceNow Certifications",
        "Certified System Administrator (CSA) Exam Guide",
        "Certified Application Developer (CAD) Exam Guide",
        "Certified Implementation Specialist (CIS) Exam Guide",
        "Tips for Certification Success",
      ],
    },
  ];


  const keyFeatures = [
    { text: "Limited Students Batch", icon: "fa-users" },
    { text: "Personalised Attention", icon: "fa-user-check" },
    { text: "Highly Qualified Teachers", icon: "fa-chalkboard-teacher" },
    { text: "Flexible Batch Timings", icon: "fa-calendar-alt" },
    { text: "Interactive Learning", icon: "fa-comments" },
    { text: "Live Projects", icon: "fa-laptop-code" },
    { text: "Career Support", icon: "fa-briefcase" },
    { text: "Job Oriented Training", icon: "fa-graduation-cap" },
  ];

  const faqs = [
    {
      question: "What is ServiceNow?",
      answer:
        "ServiceNow is a cloud-based platform designed to automate and streamline IT Service Management (ITSM), business processes, and workflows across an organization.",
    },
    {
      question: "Why is ServiceNow important?",
      answer:
        "ServiceNow improves operational efficiency, automates routine tasks, enhances collaboration, and provides a single platform for managing IT and business processes, resulting in increased productivity and reduced costs.",
    },
    {
      question: "What are the key features of ServiceNow?",
      answer:
        "Key features include Incident Management, Change Management, Problem Management, Asset Management, Knowledge Management, and customizable workflows to fit business needs.",
    },
    {
      question: "What is IT Service Management (ITSM) in ServiceNow?",
      answer:
        "ITSM in ServiceNow provides tools and processes to manage IT services, including handling incidents, service requests, changes, and configurations, ensuring high service availability and user satisfaction.",
    },
    {
      question: "What is a ServiceNow Workflow?",
      answer:
        "A ServiceNow Workflow is a series of automated steps and actions used to manage processes such as ticket routing, approvals, and notifications, enabling smooth and efficient task handling.",
    },
    {
      question: "How does ServiceNow handle integrations?",
      answer:
        "ServiceNow offers robust integration capabilities through REST, SOAP APIs, and pre-built connectors, enabling seamless connectivity with other tools and systems for data exchange and process automation.",
    },
    {
      question: "What is the CMDB in ServiceNow?",
      answer:
        "The Configuration Management Database (CMDB) is a centralized repository in ServiceNow that stores information about IT assets and their relationships, enabling better visibility and control over the IT environment.",
    },
    {
      question: "What are ServiceNow modules?",
      answer:
        "ServiceNow modules are specific applications or functionalities like ITSM, ITOM, HR Service Delivery, Customer Service Management, and more, designed to cater to various organizational needs.",
    },
    {
      question: "What certifications are available for ServiceNow?",
      answer:
        "ServiceNow offers certifications such as Certified System Administrator (CSA), Certified Implementation Specialist (CIS), and Certified Application Developer (CAD), helping professionals validate their skills and expertise.",
    },
  ];

  const whatsData = [
    {
      title: "What is ServiceNow",
      points: [
        "ServiceNow is a cloud-based platform designed to streamline and automate business processes and IT Service Management (ITSM), enabling organizations to improve efficiency and deliver better services.",
        "It provides a unified platform for managing incidents, service requests, changes, assets, and more, enhancing visibility and control over operations.",
        "ServiceNow's flexibility allows for the creation of custom workflows and integrations, making it a versatile solution for businesses of all sizes.",
      ],
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="devops-page">
      <div className="content-container-devops">
        <div className="devops-circle devops-circle-bottom-left"></div>

        {/* Left Content */}
        <div className="left-content">
          <h1>IT Accurate - Best Service Now Training in Mumbai, Thane & Navi Mumbai - Fees, Placements</h1>
          <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.6)</span>
            <span className="separator">|</span>
            <span className="ratings-count">4469 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">4367 Learners</span>
          </div>
          <p>
            Best ServiceNow Training by IT Accurate offers comprehensive ServiceNow training
            in Thane for both freshers and experienced professionals. With expert guidance
            and 100% guaranteed placement assistance, our course covers every aspect of the
            ServiceNow platform, providing you with practical, hands-on experience.
          </p>
          <p>
            Our practical, job-oriented training program not only provides you with
            a valuable certification but also equips you with the expertise needed to
            excel in real-world scenarios. We focus on efficiency, aiming to make you
            proficient in ServiceNow within just 5 months.
          </p>

          <h3>Topics covered in detail include:</h3>
          <ul className="points-list">
            <li>
              <FontAwesomeIcon icon={faFileAlt} /> Resume preparation
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice
            </li>
            <li>
              <FontAwesomeIcon icon={faBriefcase} /> 6-month internship
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities
              guaranteed program
            </li>
          </ul>
        </div>

        {/* Right Side - Contact Form */}
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>
      <StickyNavbar name="Service Now" />


      {/* <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div> */}
      <WhatsDevOps whatsData={whatsData} />
      <SalaryHikeSection />
      <BatchesPage Batches={BatchesPage} />
      <Learn course="serviceNow" />
      <Roadmap heading="Roadmap to Learn ServiceNow" roadmapData={roadmapData} />
      <JobPreparation />
      <KeyFeatures heading="ServiceNow Training" keyFeatures={keyFeatures} />
      <Syllabus heading="ServiceNow Training Syllabus" Syllabus={syllabus} />







      <section className="devops-certification-section">
        <h2 className="certification-heading">ServiceNow Certification</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>
                IT Accurate training certification will serve as proof that the
                ServiceNow courses were successfully completed with expertise.
              </li>
              <li>
                The ServiceNow certification offered by IT Accurate will equip you
                with valuable skills, enhancing your competitiveness in the IT workflow
                automation job market.
              </li>
              <li>
                IT Accurate provides comprehensive guidance for your ServiceNow
                global certification, ensuring a 100% passing guarantee in examinations.
              </li>
              <li>
                ServiceNow System Administration Certification and various other global
                certifications are covered to boost your career prospects.
              </li>
            </ul>

          </div>

          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>



      <Marq />
      <CourseOpportunities pageName="ServiceNow" />
      <Faq Faqs={faqs} />
      <MarqueeLogos />
    </div>
  );
};

export default DevOpsPage;
