import React from "react";
import { FaEnvelope, FaPhone, FaClock, FaMapMarkerAlt } from "react-icons/fa";
import ContactForm from "../components/ContactForm"; // Assuming you have a ContactForm component
import "../css/Contact.css"; // CSS file for styling
import '../components/DialogContact';
import DialogContact from "../components/DialogContact";
const ContactPage = () => {
  return (
    <div className="contact-page">
      <div className="contact-content">
        <div className="contact-text">
          <h1>Get in Touch with Us!</h1>
          <p>
            Have questions or need assistance? Our team is here to help you with all your inquiries and support needs.
          </p>
        </div>
      </div>

      <div className="main-card">
        <div className="contact-details">
          <div className="contact-item">
            <div className="icon-container-contact">
              <FaEnvelope className="contact-icon-c" />
            </div>
            <h3>Email Us</h3>
            <p>info@itaccurate.com</p>
          </div>
          <div className="contact-item">
            <div className="icon-container-contact">
              <FaPhone className="contact-icon-c" />
            </div>
            <h3>Call Us</h3>
            <p>+91 7738277389</p>
          </div>
          <div className="contact-item">
            <div className="icon-container-contact">
              <FaClock className="contact-icon-c" />
            </div>
            <h3>Opening Hours</h3>
            <p>Mon - Sat: 10 AM - 7 PM</p>
          </div>
          <div className="contact-item">
            <div className="icon-container-contact">
              <FaMapMarkerAlt className="contact-icon-c" />
            </div>
            <h3>Our Office</h3>
            <p> Office No. 806, 8th Floor, Paradise Tower, next to McDonald's, Naupada, Thane West, Mumbai, Thane, Maharashtra 400601</p>
          </div>
        </div>
      </div>

      {/* Separate Form and Map Section */}
      <div className="form-map-container">
        <div className="form-container">
          <DialogContact className="form-contact" title="Get in Touch" submitText="Submit" />
        </div>
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.1735468124407!2d72.97272317346287!3d19.187620882040004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b90d997fd167%3A0x5439eb8e12dbc517!2sIT%20Accurate!5e0!3m2!1sen!2sin!4v1743227390680!5m2!1sen!2sin"
            width="100%"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Office Location"
          ></iframe>
         
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
