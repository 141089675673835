import { useState } from "react";
import { ChevronDown } from "lucide-react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export default function DesktopMenu({ menu }) {
  const [isHover, setHover] = useState(false);
  const [activeSubMenuIndex, setActiveSubMenuIndex] = useState(null);

  const subMenuAnimate = {
    enter: {
      opacity: 1,
      translateY: 0,
      display: "block",
      transition: { duration: 0.4 },
    },
    exit: {
      opacity: 0,
      translateY: -10,
      display: "none",
      transition: { duration: 0.3, delay: 0.2 },
    },
  };

  const handleMouseEnter = (index) => {
    setHover(true);
    setActiveSubMenuIndex(index);
  };

  const handleMouseLeave = () => {
    setHover(false);
    setActiveSubMenuIndex(null);
  };

  return (
    <motion.li
      className="relative group"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={handleMouseLeave}
    >
      <span className="flex items-center gap-1  hover:bg-white/5 cursor-pointer px-3 py-1 rounded-xl">
        <Link to={menu.path || "#"} className="flex items-center gap-1">
          {menu.name}
          {menu?.subMenu?.length > 0 && (
            <ChevronDown className="transition-transform group-hover:rotate-180 duration-300" />
          )}
        </Link>
      </span>

      {menu?.subMenu?.length > 0 && (
        <motion.div
          className="absolute top-full -left-[5rem] mt-2 bg-white/10 border border-blue-300 backdrop-blur-lg shadow-xl  w-[500%]"
          initial="exit"
          animate={isHover ? "enter" : "exit"}
          variants={subMenuAnimate}
        >
          <ul className="grid w-full grid-cols-2 ">
            {menu.subMenu.map((submenu, i) => (
              <li
                key={i}
                className="relative flex items-center gap-2 p-4 hover:bg-gray-300 border-b border-r border-blue-200"
                onMouseEnter={() => handleMouseEnter(i)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="w-8 h-8 flex items-center justify-center bg-white/5 text-blue-500 rounded-md group-hover/menubox:bg-blue-300 group-hover/menubox:text-white duration-300">
                  {submenu.logoUrl ? (
                    <img
                      src={submenu.logoUrl}
                      alt={submenu.name}
                      className="w-full h-full object-contain"
                    />
                  ) : (
                    submenu.icon && <submenu.icon />
                  )}
                </div>

                <div>
                  <h6 className="text-white font-semibold text-base">
                    <Link to={submenu.path || "#"}>{submenu.name}</Link>
                  </h6>
                  <p className="text-sm text-gray-800">{submenu.desc}</p>
                </div>

                {submenu.minisubMenus &&
                  submenu.minisubMenus.length > 0 &&
                  activeSubMenuIndex === i && (
                    <motion.div
                      className={`absolute top-0 ${
                        submenu.direction === "left"
                          ? "right-full"
                          : "left-full"
                      } ml-1 mt-1 border-t border-blue-200 bg-white/90 backdrop-blur-lg shadow-lg w-44 overflow-y-auto`}
                      style={{ maxHeight: "426px" }} // Fixed height for the submenu
                      initial="exit"
                      animate="enter"
                      exit="exit"
                    >
                      <ul>
                        {submenu.minisubMenus.map((minisubMenu, j) => (
                          <Link
                            key={j}
                            to={minisubMenu.path || "#"}
                            className="block text-[14px] border-b border-l border-r border-blue-200 py-2 px-5 text-black font-semibold hover:bg-gray-300"
                          >
                            <li className="cursor-pointer">
                              {minisubMenu.name}
                            </li>
                          </Link>
                        ))}
                      </ul>
                    </motion.div>
                  )}
              </li>
            ))}
          </ul>
        </motion.div>
      )}
    </motion.li>
  );
}
