import React, { useEffect, useRef } from "react";
import "../css/DevOps.css";
import certificateImg from "../assets/Certificates/IT accurate certificate.png";
import Roadmap from "../components/Roadmap";
import WhatsDevOps from "../components/WhatsDevOps";
import Syllabus from "../components/Syllabus";
import KeyFeatures from "../components/KeyFeatures";
import Faq from "../components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
import ContactForm from "../components/ContactForm";
import MarqueeLogos from "../components/MarqueeLogos";
import Learn from '../components/Learn';
import Marq from '../components/CustomMarquee';
import JobPreparation from '../components/JobPreparation';
import BatchesPage from "../components/Batches";
import SalaryHikeSection from '../components/SalaryHike';
import StickyNavbar from "../components/StickyNavbar";

// import rev1 from '../assets/Reviews/python/1.png';
// import rev2 from '../assets/Reviews/python/2.png';
// import rev3 from '../assets/Reviews/python/3.png';
// import rev4 from '../assets/Reviews/python/4.png';
// import rev5 from '../assets/Reviews/python/5.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle, faStar } from '@fortawesome/free-solid-svg-icons';
import CourseOpportunities from '../components/CourseOpportunities'


const DevOpsPage = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


  // useEffect(() => {
  //   // Function to create the YouTube player
  //   const createPlayer = () => {
  //     if (window.YT && window.YT.Player) {
  //       playerRef.current = new window.YT.Player(videoRef.current, {
  //         videoId: 'i3HdVH6gdfE', // Updated YouTube video ID
  //         events: {
  //           onReady: (event) => {
  //             event.target.mute(); // Mute the video to allow autoplay
  //             // event.target.playVideo();
  //           },
  //         },
  //         playerVars: {
  //           loop: 1,
  //           controls: 0, // Hide video controls
  //           modestbranding: 1, // Minimal YouTube branding
  //           playlist: 'i3HdVH6gdfE', // Required for looping
  //         },
  //       });
  //     }
  //   };

  //   // Load YouTube IFrame API if it's not already loaded
  //   if (!window.YT) {
  //     const tag = document.createElement('script');
  //     tag.src = 'https://www.youtube.com/iframe_api';
  //     const firstScriptTag = document.getElementsByTagName('script')[0];
  //     firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //     // Set up callback for when the API is ready
  //     window.onYouTubeIframeAPIReady = () => {
  //       createPlayer();
  //     };
  //   } else {
  //     createPlayer();
  //   }
  // }, []);


  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What is Data analytics ?',
        'Data Analytics course',
        'Data Analytics certification',
        'Data Analytics jobs in india',
        'Data Analytics jobs in Thane',
      ]
    },
    {
      title: ' Basics of Python',
      points: [
        'Understand basic syntax and data types (strings, numbers, lists, dictionaries).',
        'Learn about control flow (if statements, loops) and functions.',
        'Practice writing simple programs and solving coding challenges.',
      ]
    },
    {
      title: 'Intermediate Python',
      points: [
        'Dive deeper into data structures like tuples, sets, and advanced lists.',
        'Explore file handling, exception handling, and working with modules.',
        'Gain proficiency in using libraries such as NumPy for numerical computing.',
      ]
    },
    {
      title: 'Object-Oriented Programming (OOP)',
      points: [
        'Learn OOP concepts like classes, objects, inheritance, and polymorphism.',
        'Practice designing and implementing classes for real-world scenarios.',
        'Understand the importance of encapsulation, abstraction, and inheritance.',
      ]
    },
    {
      title: 'Web Development with Python',
      points: [
        'Study web frameworks like Flask or Django for building web applications.',
        'Learn about routing, templates, forms, and database integration.',
        'Create a simple web application and deploy it on a local server.',
      ]
    },
    {
      title: ' Data Science and Machine Learning',
      points: [
        'Explore data manipulation and analysis using libraries like Pandas and Matplotlib.',
        'Dive into machine learning concepts like supervised learning, unsupervised learning, and model evaluation.',
        'Apply machine learning algorithms using libraries like Scikit-Learn and TensorFlow.',
      ]
    },
    {
      title: 'Advanced Topics and Projects',
      points: [
        'Explore advanced Python concepts like decorators, generators, and context managers.',
        'Work on mini-projects to reinforce your learning and showcase your skills.',
        'Collaborate with others on open-source projects or participate in hackathons to gain practical experience.',
      ]
    },
    {
      title: 'Resume preparation',
      points: [
        'Include keywords',
        'How to prepare resume',
        'How to prepare resume for freshers.',
        'Show impact',
        'Include soft skills',
        'Be unique',
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Python for AI',
      subpoints: [
        'Introduction of Python',
        'Why python is the best for AI',
        'Basic of python',
        'Arithmetic and logical operator',
        'Input and Output Methods',
        'Data types in python',
        'String data type and operation',
        'List data types and operation',
        'Sets data type and operation',
        'Dictionary data type and operation',
        'Tuple data type and operation',
        'Integer data type and operation',
        'Condition in python: If, Elif, Else',
        'Nested Condition in python',
        'Loop in python: For Loop and While Loop',
        'Nested loop in python',
        'Pre-define Function',
        'User-define Function Unpack operators (*args & ,** kwargs)',
        'List Comprehension and lambda Function',
        'Raising and Handling Exception (Try and Except)',
        'Logging module',
        'File handling',
        'OS module',
        'F- string',
        'Oops (Object Oriented Programming)',
      ],
    },
    {
      title: 'Statistics for AI',
      subpoints: [
        ' Introduction of Statistics',
        ' Type of statistics',
        ' Population and Sampling',
        ' Descriptive Statistics',
        ' Inferential Statistics',
        ' Hypothesis Testing',
        ' Statistics with python',
      ],
    },
    {
      title: 'Python Library for AI',
      subpoints: [
        'Pandas',
        'NumPy',
        'Matplotlib',
        'Seaborn',
        'Scikit Learn',
        'Math Module',
        'Datetime',
        'Statistics module',
        'Scipy',
      ],
    },
    {
      title: 'Python with Database',
      subpoints: [
        'SQL',
        'NoSQL',
      ],
    },
    {
      title: 'Introduction of AI',
      subpoints: [
        ' Basic of Machine Learning',
        'Basic of Deep Learning',
      ],
    },
    {
      title: 'Interview Preparation',
      subpoints: [
        'Interview Question',
        'Python project',
        'Profile Building',
        'Mock Interview',
      ],
    },
    {
      title: 'Bonus Topics',
      subpoints: [
        'GitHub',
        'Chat GPT',
        'VS Code',
      ],
    },

  ];

  const keyFeatures = [
    { text: "80+ Hours Course Duration", icon: "fa-clock" },
    { text: "100% Job Oriented Training", icon: "fa-graduation-cap" },
    { text: "Industry Expert Faculties", icon: "fa-user-tie" },
    { text: "Free Demo Class Available", icon: "fa-chalkboard" },
    { text: "Completed 159+ Batches", icon: "fa-users" },
    { text: "Certification Guidance", icon: "fa-certificate" }
  ];

  const faqs = [
    {
      question: "What is Python?",
      answer: "Python is a high-level programming language known for its simplicity and readability.",
    },
    {
      question: "What are the key features of Python?",
      answer: "Key features include easy-to-read syntax, support for multiple programming paradigms, extensive standard libraries, and dynamic typing.",
    },
    {
      question: "Is Python free?",
      answer: "Yes, Python is an open-source language, and it’s freely available for download and use.",
    },
    {
      question: "What can you do with Python?",
      answer: "Python is versatile and used for web development, data analysis, artificial intelligence, automation, scientific computing, and more.",
    },
    {
      question: "How do I install Python?",
      answer: "You can download Python from the official website and follow the installation instructions for your operating system.",
    },
    {
      question: "What is the difference between Python 2 and Python 3?",
      answer: "Python 2 is an older version with limited support, while Python 3 is the current and recommended version with improvements and new features.",
    },
    {
      question: "What are Python frameworks?",
      answer: "Python frameworks like Django, Flask, and Pyramid provide structures and tools for building web applications efficiently.",
    },
    {
      question: "Is Python good for beginners?",
      answer: "Yes, Python’s readability and simplicity make it an excellent choice for beginners learning programming.",
    }
  ];

  const whatsData = [
    {
      title: 'What is Python ?',
      points: [
        'Python is a high-level programming language known for its simplicity and readability, making it easy to learn and use.',
        'It supports multiple programming paradigms, including procedural, object-oriented, and functional programming, offering flexibility in coding styles.',
        'Python has a vast ecosystem of libraries and frameworks for tasks ranging from web development to data analysis and machine learning, making it widely used across different domains.',
        'IT Accurate covers all the Python skills to get you hired in 2024.',
        'IT Accurate training provides Python training in offline and online mode. Support with real-time Python project-based training.',
        'IT background, non-IT background, freshers, and experienced candidates can start their career in Python, irrespective of their background.',
        'IT Accurate is the best training institute in Thane with 100% job opportunities.'
      ]
    }
  ];


  // const sliderSettings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  // const reviewList = [
  //   { Image: rev1, alt: "rev1" },
  //   { Image: rev2, alt: "rev1" },
  //   { Image: rev3, alt: "rev1" },
  //   { Image: rev4, alt: "rev1" },
  //   { Image: rev5, alt: "rev1" },
  // ]

  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
        <h1>IT Accurate - Best Python Training in Mumbai, Thane & Navi Mumbai - Fees, Placements</h1>
          <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.7)</span>
            <span className="separator">|</span>
            <span className="ratings-count">4489 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">4907 Learners</span>
          </div>
          <p>
            Best Python Training by IT Accurate offers you the best Python training in Thane for freshers and experienced candidates in Thane, with expert guidance and 100% guaranteed placement assistance. Our Python training course covers every technical topic in detail, and the student is left with real-time hands-on experience.
          </p>
          <p>
            Our practical, job-oriented training program will not only provide you with a certificate but also with knowledge equivalent to around 4+ years of field experience. We value your time as much as ours. Hence, we aim to train you in Python in just 1.5 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> GitHub</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Chat GPT</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> Interview preparation</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> Case studies</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 2 Months internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> With 100% job opportunities guaranteed program</li>
          </ul>
        </div>

        {/* Right Side - Contact Form */}
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>

      {/* <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div> */}
      <StickyNavbar name="Python" />
      <WhatsDevOps whatsData={whatsData} />
      <SalaryHikeSection />
      <BatchesPage Batches={BatchesPage} />
      <Learn course="python" />
      <Roadmap heading="Roadmap to learn Python" roadmapData={roadmapData} />
      <JobPreparation />
      <KeyFeatures heading="Python Training" keyFeatures={keyFeatures} />
      <Syllabus heading="Python Training syllabus" Syllabus={syllabus} />

      {/* <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for Python Training</h2>
        <p>
          Sapalogy provides flexible timings to all our students. Here are the Python Training Classes in Nagpur Schedule in our branches. If this schedule doesn’t match please let us know. We will try to arrange appropriate timings based on your flexible timing.
        </p>
        <div className="batch-schedule-table-wrapper">
          <table className="batch-schedule-table">
            <thead>
              <tr>
                <th>Course</th>
                <th>Batch Time</th>
                <th>Offline</th>
                <th>Online</th>
                <th>Enquire Now</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Python</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
              <tr>
                <td>Data analytics</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
              <tr>
                <td>Data science</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
            </tbody>
          </table>
        </div></div> */}

      <section id="certification" className="devops-certification-section">
        <h2 className="certification-heading">Python certification</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>IT Accurate certification will serve as proof that the courses were successfully completed.</li>
              <li>The Python certification offered by IT Accurate will equip you with essential skills, boosting your competitiveness in the job market.</li>
              <li>IT Accurate provides full support for your Python global certification, ensuring a 100% pass rate in exams such as Data Analytics Certification, Data Analyst Platform Development Certification, and various other global certifications.</li>
            </ul>

          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      <Marq />
      <CourseOpportunities pageName="Python" />
      <Faq Faqs={faqs} />
      <MarqueeLogos />
    </div>
  );
};

export default DevOpsPage;