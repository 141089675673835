import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Make sure to import useParams
import '../css/BlogList.css';
import { client } from '../contentful/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const BlogDetails = () => {
    const { id } = useParams(); // Get the blog post ID from the URL
    const [blogPost, setBlogPost] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getBlogPost = async () => {
            try {
                const entries = await client.getEntries({
                    'sys.id': id // Fetch the blog post by ID
                });
                if (entries.items.length > 0) {
                    setBlogPost(entries.items[0]); // Set the first found entry
                }
            } catch (error) {
                console.error("Error fetching blog post:", error);
            } finally {
                setLoading(false);
            }
        };
        getBlogPost();
    }, [id]);

    useEffect(() => {
        if (blogPost?.fields?.blogTitle) {
            document.title = blogPost.fields.blogTitle;
        }
    }, [blogPost]);

    return (
        <div>
            {loading ? (
                <section className="skeleton-container">
                    <div className="skeleton-loader skeleton-title"></div>
                    <div className="skeleton-loader skeleton-image"></div>
                    <div className="skeleton-loader skeleton-paragraph"></div>
                    <div className="skeleton-loader skeleton-paragraph"></div>
                    <div className="skeleton-loader skeleton-subheading"></div>
                    <div className="skeleton-loader skeleton-paragraph"></div>
                    <div className="skeleton-loader skeleton-paragraph"></div>
                    <div className="skeleton-loader skeleton-image"></div>
                    <div className="skeleton-loader skeleton-paragraph"></div>
                </section>
            ) : blogPost ? (
                <section className="blog-detail-container" key={blogPost.sys.id}>
                    <h1 className="blog-title">{blogPost.fields.blogTitle}</h1>

                    <h3 className="blog-subheading">{blogPost.fields.blogt1}</h3>
                    <div className='md:flex md:justify-around gap-5'>
                        <img src={blogPost.fields.blogImg?.fields?.file?.url}
                            alt="Blog-image"
                            className="blog-image"
                        />
                        <p className="blog-paragraph">
                            {documentToReactComponents(blogPost.fields.richtext)}
                        </p>
                    </div>

                    <h3 className="blog-subheading left-2">{blogPost.fields.blogt2}</h3>
                    <div className='md:flex md:justify-around gap-5'>
                        <p className="blog-paragraph">
                            {documentToReactComponents(blogPost.fields.richtext2)}
                        </p>
                        <img
                            src={blogPost.fields.blogImg2?.fields?.file?.url}
                            alt="Blog-image"
                            className="blog-image"
                        />

                    </div>

                    <h3 className="blog-subheading">{blogPost.fields.blogt3}</h3>
                    <div className='md:flex md:justify-around gap-5'>
                        <img
                            src={blogPost.fields.blogImg3?.fields?.file?.url}
                            alt="Blog-image"
                            className="blog-image"
                        />
                        <p className="blog-paragraph">
                            {documentToReactComponents(blogPost.fields.richtext3)}
                        </p>
                    </div>

                    <h3 className="blog-subheading">{blogPost.fields.blogt4}</h3>
                    <div className='md:flex md:justify-around gap-5'>
                        <p className="blog-paragraph">
                            {documentToReactComponents(blogPost.fields.richtext4)}
                        </p>
                        <img
                            src={blogPost.fields.blogImg4?.fields?.file?.url}
                            alt="Blog-image"
                            className="blog-image"
                        />

                    </div>

                    <h3 className="blog-subheading">Conclusion</h3>
                    <p className="blog-paragraph">
                        {documentToReactComponents(blogPost.fields.conclusionrichtext)}
                    </p>
                    <img
                        src={blogPost.fields.promotionPoster?.fields?.file?.url}
                        alt="sapalogy-img"
                        className="m-auto mt-3 w-[50%]"
                    />

                    <p className="blog-paragraph">
                        {blogPost.fields.infoPara2}
                    </p>
                    <div className="w-full mt-3">
                        <h2 className="text-2xl font-semibold text-gray-800">Contact IT-Accurate</h2>

                        <ul className='flex-col mt-4'>
                            <li className='flex'><p className="font-medium font-semibold text-gray-700">Instagram:</p>
                                <a
                                    href="https://www.instagram.com/itaccurate/?igsh=MWxsOHhmenV4cjhnZQ%3D%3D"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-600 mx-2 hover:underline break-words"
                                >
                                    https://www.instagram.com/itaccurate/?igsh=MWxsOHhmenV4cjhnZQ%3D%3D
                                </a></li>
                            <li className='flex'>
                                <p className="font-medium font-semibold text-gray-700">Facebook:</p>
                                <a
                                    href="https://www.facebook.com/ITAccurate"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-600 mx-2 hover:underline"
                                >
                                    https://www.facebook.com/ITAccurate
                                </a>
                            </li>
                            <li className='flex'>
                                <p className="font-medium font-semibold text-gray-700">Website:</p>
                                <a
                                    href="https://www.itaccurate.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-600 mx-2 hover:underline"
                                >
                                    www.itaccurate.com
                                </a>
                            </li>
                            <li className='flex'>
                                <p className="font-medium font-semibold text-gray-700">Email:</p>
                                <a
                                    href="mailto:info@itaccurate.com"
                                    className="text-blue-600 mx-2 hover:underline"
                                >
                                    info@itaccurate.com
                                </a>
                            </li>
                            <li className='flex'>
                                <p className="font-medium font-semibold text-gray-700">Contact Number:</p>
                                <a
                                    href="tel:9175978889"
                                    className="text-blue-600 mx-2 hover:underline"
                                >
                                    +91-9175978889
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="blog-paragraph mt-4">
                        {documentToReactComponents(blogPost.fields.lastpara)}
                    </div>
                </section>
            ) : (
                <p>No blog post found.</p>
            )}
        </div>
    );
};

export default BlogDetails;
