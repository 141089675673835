import "../../css/DevOps.css";
import certificateImg from '../../assets/Certificates/IT accurate certificate.png';
import Roadmap from "../../components/Roadmap";
import WhatsDevOps from "../../components/WhatsDevOps";
import Syllabus from "../../components/Syllabus";
import KeyFeatures from "../../components/KeyFeatures";
import Faq from "../../components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
import StickyNavbar from "../../components/StickyNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFileAlt,
    faChalkboardTeacher,
    faBriefcase,
    faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import MarqueeLogos from "../../components/MarqueeLogos";
import ContactForm from "../../components/ContactForm";
import CourseOpportunities from '../../components/CourseOpportunities'
import Batches from "../../components/Batches"


const SapBTP = () => {
    const modulesData = [
        {
            title: "SAP",
            points: [
                "IT Accurate Training in Thane offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.",
            ],
            link: "/sap",
        },
        {
            title: "SAP BASIS",
            points: [
                "IT Accurate Training in Thane offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.",
            ],
            link: "/sap-basis",
        },
        {
            title: "SAP MM",
            points: [
                "SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. IT Accurate Institute in Thane offers comprehensive training in SAP MM for effective utilization.",
            ],
            link: "/sap-mm",
        },
        {
            title: "SAP FICO",
            points: [
                "SAP FICO, offered by IT Accurate Institute Thane, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations.",
            ],
            link: "/sap-fico",
        },
        {
            title: "SAP ABAP",
            points: [
                "SAP ABAP is a programming language used for developing applications within the SAP ecosystem. IT Accurate Institute in Thane is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.",
            ],
            link: "/sap-abap",
        },
        {
            title: "SAP HCM",
            points: [
                "SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. IT Accurate Institute in Thane likely provides training in SAP HCM and related modules.",
            ],
            link: "/sap-hcm",
        },
        {
            title: " SAP SuccessFactors",
            points: [
                "SAP SuccessFactors is a cloud-based Human Capital Management (HCM) solution for managing HR functions like recruitment, performance, learning, and employee engagement.",
            ],
            link: "/sap-successfactors",
        },
        {
            title: "SAP SCM",
            points: [
                "SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.",
            ],
            link: "/sap-scm",
        },
        {
            title: "SAP ARIBA",
            points: [
                "SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. IT Accurate Institute Thane is an educational institution specializing in SAP training and certification.",
            ],
            link: "/sap-ariba",
        },
        {
            title: "SAP PP",
            points: [
                "SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.",
            ],
            link: "/sap-pp",
        },
        {
            title: "SAP SD",
            points: [
                "SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.",
            ],
            link: "/sap-sd",
        },
        {
            title: "SAP FIORI",
            points: [
                "SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.",
            ],
            link: "/sap-fiori",
        },
        {
            title: "SAP QM",
            points: [
                "SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.",
            ],
            link: "/sap-qm",
        },
        {
            title: "SAP PM",
            points: [
                "SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.",
            ],
            link: "/sap-pm",
        },
        {
            title: "SAP WM & EWM",
            points: [
                "SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.",
            ],
            link: "/sap-wmewm",
        },
        {
            title: "SAP LE & SL",
            points: [
                "SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.",
            ],
            link: "/sap-lesl",
        },
    ];

    const roadmapData = [
        {
            title: 'Introduction',
            points: [
                'What is SAP?',
                'What is SAP BTP?',
                'What is SAP HANA?',
                'SAP course',
                'SAP certification',
                'SAP jobs in India',
                'SAP jobs in Nagpur',
            ]
        },
        {
            title: 'BTP Development and Integration',
            points: [
                "Understand and implement SAP BTP concepts.",
                "Build and integrate applications using SAP BTP services.",
            ]
        },
        {
            title: 'Debugging and Performance Optimization',
            points: [
                "Master debugging techniques in SAP BTP.",
                "Learn strategies for optimizing code and database queries in BTP.",
            ]
        },
        {
            title: 'Application Development',
            points: [
                'Develop cloud applications using SAP BTP tools.',
                'Customize applications to meet business requirements.',
            ]
        },
        {
            title: 'BTP Extensions',
            points: [
                'Learn to create extensions for SAP S/4HANA using SAP BTP.',
                'Understand the extension options available within BTP.',
            ]
        },
        {
            title: 'BTP Security and Compliance',
            points: [
                'Implement security best practices in SAP BTP.',
                'Understand compliance requirements and solutions.',
            ]
        },
        {
            title: 'SAP Integration Suite',
            points: [
                'Learn SAP Integration Suite for seamless data exchange.',
                'Enable communication between SAP systems and external applications.',
            ]
        },
        {
            title: 'Resume Preparation',
            points: [
                'Include relevant keywords.',
                'How to prepare a resume.',
                'How to prepare a resume for freshers.',
                'Show impact.',
                'Include soft skills.',
                'Be unique.',
            ]
        }
    ];

    const syllabus = [
        {
            title: 'Introduction',
            subpoints: [
                'What is SAP?',
                'History & Features of SAP',
                'SAP BTP Architecture',
                'SAP BTP Services Overview',
                'SAP S/4HANA Integration with BTP',
                'SAP Landscape Overview',
            ],
        },
        {
            title: 'SAP BTP Basics',
            subpoints: [
                'Introduction to SAP BTP',
                'Logon to SAP BTP Environment',
                'Using SAP BTP Cockpit',
                'Cloud Foundry Basics',
                'SAP BTP Trial Account Setup',
                'Using SAP BTP Services',
            ],
        },
        {
            title: 'BTP Development Environment',
            subpoints: [
                'Understanding the Development Environment',
                'Using SAP Business Application Studio',
                'Deployment Strategies for SAP BTP Applications',
                'Best Practices for Cloud Application Development',
            ],
        },
        {
            title: 'Integration Services',
            subpoints: [
                'Introduction to SAP Integration Suite',
                'Data Integration with SAP Data Intelligence',
                'API Management in SAP BTP',
                'Workflow Automation',
            ],
        },
        {
            title: 'BTP Security and Compliance',
            subpoints: [
                'Understanding Identity Authentication Services',
                'Role-Based Access Control (RBAC)',
                'Data Security and Privacy in SAP BTP',
            ],
        },
        {
            title: 'Advanced Application Development',
            subpoints: [
                'Introduction to SAP Fiori',
                'Building Fiori Apps with BTP',
                'Extending SAP Applications with BTP Extensions',
            ],
        },
        {
            title: 'SAP Analytics',
            subpoints: [
                'Using SAP Analytics Cloud',
                'Embedding Analytics in Applications',
                'Data Visualization Techniques',
            ],
        },
        {
            title: 'BTP Certification Preparation',
            subpoints: [
                'Certification Overview',
                'Preparation Tips',
                'Mock Tests and Resources',
            ],
        },
    ];

    const keyFeatures = [
        { text: "Limited Students Batch", icon: "fa-users" },
        { text: "Personalised Attention", icon: "fa-user-check" },
        { text: "Highly Qualified Teachers", icon: "fa-chalkboard-teacher" },
        { text: "Flexible Batch Timings", icon: "fa-calendar-alt" },
        { text: "Interactive Learning", icon: "fa-comments" },
        { text: "Live Projects", icon: "fa-laptop-code" },
        { text: "Career Support", icon: "fa-briefcase" },
        { text: "Job Oriented Training", icon: "fa-graduation-cap" },
    ];

    const faqs = [
        {
            question: "What is SAP BTP?",
            answer: "SAP BTP (Business Technology Platform) is a comprehensive cloud-based platform for developing and integrating applications, analyzing data, and extending SAP solutions.",
        },
        {
            question: "What is the difference between SAP BTP and SAP?",
            answer: "SAP refers to the overall ERP system and suite of applications, while SAP BTP is a cloud-based platform focused on integration, extension, and analytics capabilities.",
        },
        {
            question: "What services does SAP BTP provide?",
            answer: "SAP BTP provides services for application development, integration, data management, analytics, and extension of SAP applications.",
        },
        {
            question: "How is SAP BTP used in real-world scenarios?",
            answer: "SAP BTP is used to build custom applications, extend existing SAP solutions, integrate data across systems, and perform advanced analytics.",
        },
        {
            question: "What certifications are available for SAP BTP?",
            answer: "Certifications such as SAP Certified Development Associate for BTP and SAP Certified Application Associate for SAP Integration Suite are available.",
        },
        {
            question: "What programming languages can be used in SAP BTP?",
            answer: "SAP BTP supports various programming languages, including Java, JavaScript, and Python.",
        },
        {
            question: "How to prepare for SAP BTP certification?",
            answer: "You can prepare by taking relevant training courses, accessing SAP learning resources, and practicing with mock tests.",
        },
        {
            question: "What is the SAP Integration Suite?",
            answer: "SAP Integration Suite is a service within SAP BTP that enables seamless integration between SAP and non-SAP systems.",
        },
        {
            question: "How secure is SAP BTP?",
            answer: "SAP BTP provides advanced security measures, including role-based access, data encryption, and compliance with global standards.",
        },
        {
            question: "Can SAP BTP be used with non-SAP systems?",
            answer: "Yes, SAP BTP allows integration with both SAP and non-SAP systems through its integration services.",
        },
    ];

    const whatsData = [
        {
            title: 'What is SAP BTP?',
            points: [
                'SAP BTP (Business Technology Platform) is a cloud-based solution for integrating, extending, and developing applications in the SAP ecosystem.',
                'It provides tools for application development, data management, and analytics.',
                'SAP BTP enables businesses to customize workflows and enhance processes for various modules, including finance, logistics, and human resources.',
                'Sapalogy offers SAP BTP training in both online and offline modes, covering real-time project-based scenarios.',
                'The training is open to IT and non-IT backgrounds, freshers, and experienced professionals alike.',
                'Sapalogy is the best training institute in Nagpur with 100% job opportunities.',
            ]
        }
    ];

    return (
        <div className="devops-page">
            {/* <SideDropdown roadmapData={modulesData} /> */}
            <div className="content-container">
                <div className="devops-circle devops-circle-bottom-left"></div>
                <div className="left-content">
                    <h1>IT Accurate - Best SAP BTP Training in Mumbai, Thane & Navi Mumbai - Fees, Placements</h1>
                    <p>
                        IT Accurate provides top-notch SAP BTP training for both freshers
                        and experienced candidates in Thane. With expert guidance and a
                        100% job placement guarantee, our training covers every technical
                        topic in detail, ensuring real-time, hands-on experience.
                    </p>
                    <br />
                    <p>
                        Our practical, job-oriented training program not only awards you a
                        certificate but also equips you with knowledge equivalent to 2+
                        years of industry experience. We value your time and offer SAP BTP
                        with S/4 HANA training that can be completed in just 4 months. IT
                        Accurate’s SAP training institute in Thane delivers practical,
                        job-oriented training, and our SAP BTP with S/4 HANA course is
                        designed to provide the equivalent of 7+ years of field experience,
                        all within a 4-month timeframe.
                    </p>

                    <h3>All the topics will be covered in detail and also include:</h3>
                    <ul className="points-list">
                        <li>
                            <FontAwesomeIcon icon={faFileAlt} /> End user 1 month (ECC / S4
                            HANA)
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faChalkboardTeacher} /> Configuration 2
                            months (ECC / S4 HANA)
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faBriefcase} /> Project 1 month
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faFileAlt} /> Resume preparation
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faBriefcase} /> 6-months internship
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities
                            guaranteed program
                        </li>
                    </ul>
                </div>
                <div className="form-contact">
                    <ContactForm title="Get in Touch" submitText="Submit" />
                </div>
            </div>
            <StickyNavbar name="SapBTP" />
            <WhatsDevOps whatsData={whatsData} />
            <Batches/>
            <Roadmap heading="Roadmap to Learn SAP BTP" roadmapData={roadmapData} />
            <Syllabus heading="SAP BTP Training Syllabus" Syllabus={syllabus} />
            <KeyFeatures heading="SAP BTP Training" keyFeatures={keyFeatures} />
            <section id="certification" className="devops-certification-section">
                <h2 className="certification-heading">SAP BTP CERTIFICATION</h2>
                <div className="certification-content">
                    <div className="certification-points">
                        <ul>
                            <li>
                                IT Accurate training certification will serve as proof that the courses were completed by IT Accurate.
                            </li>
                            <li>
                                The SAP BTP certification offered by IT Accurate will equip you with valuable skills, enhancing your competitiveness in the job market.
                            </li>
                            <li>
                                IT Accurate provides comprehensive guidance for your SAP BTP global certification, ensuring a 100% passing guarantee in examinations such as SAP Certification, SAP S/4HANA Platform Development Certification, and various other global exams.
                            </li>
                        </ul>

                    </div>
                    <div className="certification-image">
                        <img src={certificateImg} alt="DevOps Certification" />
                    </div>
                </div>
            </section>
            <Roadmap heading="SAP modules" roadmapData={modulesData} />
            <CourseOpportunities pageName="SAP_BTP" />
            <Faq Faqs={faqs} />
            <MarqueeLogos />
        </div>
    );
};

export default SapBTP;
