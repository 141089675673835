import React from 'react';
import WhatsAppIcon from '../assets/WhatsApp_icon.png'; // Path to your WhatsApp image
import CallIcon from '../assets/call_icon.jpg'; // Path to your Call image

const Floating = () => {
    return (
        <div>
            {/* WhatsApp Floating Button */}
            <div style={{
                position: 'fixed',
                bottom: '20px',
                right: '14px',
                width: '60px',
                height: '60px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                zIndex: 1000
            }}>
                <a href="https://wa.me/7738913001" target="_blank" rel="noopener noreferrer">
                    <img
                        src={WhatsAppIcon}
                        alt="WhatsApp"
                        style={{ width: '100%', height: '100%',}}
                    />
                </a>
            </div>

            {/* Call Floating Button */}
            <div style={{
                position: 'fixed',
                bottom: '90px',
                right: '20px',
                width: '50px',
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                zIndex: 1000
            }}>
                <a href="tel:+917738913001">
                    <img
                        src={CallIcon}
                        alt="Call"
                        style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                    />
                </a>
            </div>
        </div>
    );
}

export default Floating;
