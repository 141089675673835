import React, { useEffect, useRef } from "react";
import "../css/DevOps.css";
import certificateImg from "../assets/Certificates/IT accurate certificate.png";
import Roadmap from "../components/Roadmap";
import WhatsDevOps from "../components/WhatsDevOps";
import Syllabus from "../components/Syllabus";
import KeyFeatures from "../components/KeyFeatures";
import Faq from "../components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Learn from '../components/Learn';
import Marq from '../components/CustomMarquee';
import JobPreparation from '../components/JobPreparation';
import BatchesPage from "../components/Batches";
import SalaryHikeSection from '../components/SalaryHike';
import StickyNavbar from "../components/StickyNavbar";

import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../components/ContactForm";
import MarqueeLogos from "../components/MarqueeLogos";
import CourseOpportunities from '../components/CourseOpportunities'


const AIML = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


  useEffect(() => {
    // Function to create the YouTube player
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(videoRef.current, {
          videoId: '9skLBO4K-zI', // Updated YouTube video ID
          events: {
            onReady: (event) => {
              // event.target.mute(); // Mute the video to allow autoplay
              event.target.playVideo();
            },
          },
          playerVars: {
            loop: 1,
            controls: 0, // Hide video controls
            modestbranding: 1, // Minimal YouTube branding
            playlist: '9skLBO4K-zI', // Required for looping
          },
        });
      }
    };

    // Load YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up callback for when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }
  }, []);

  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What is Full stack developer?',
        'Full stack developer course',
        'Full stack developer certification',
        'Full stack developer jobs in india.',
        'Full stack developer jobs in Thane'
      ]
    },
    {
      title: ' Front-end Development',
      points: [
        'Learn HTML and CSS fundamentals.',
        'Master JavaScript for dynamic web page functionalities.',
        'Explore popular front-end frameworks like React or Angular for building interactive user interfaces.'
      ]
    },
    {
      title: 'Back-end Development',
      points: [
        'Understand server-side programming with Node.js or Python.',
        'Learn about databases and data storage using SQL (e.g., MySQL) or NoSQL (e.g., MongoDB).',
        'Explore RESTful APIs for communication between front-end and back-end systems.'
      ]
    },
    {
      title: 'Database Management',
      points: [
        'Gain proficiency in database design principles.',
        'Learn SQL for querying and manipulating data in relational databases.',
        'Explore NoSQL databases like MongoDB for handling unstructured data.'
      ]
    },
    {
      title: ' Full Stack Frameworks',
      points: [
        'Dive into full stack frameworks like MERN (MongoDB, Express.js, React, Node.js) or MEAN (MongoDB, Express.js, Angular, Node.js).',
        'Understand the architecture and components of these frameworks for building end-to-end applications.'
      ]
    },
    {
      title: ' Version Control and Collaboration',
      points: [
        'Learn version control using Git and platforms like GitHub for collaborative development.',
        'Understand branching, merging, and pull requests for efficient team collaboration.',

      ]
    },
    {
      title: 'Deployment and DevOps',
      points: [
        'Explore deployment strategies using platforms like Heroku, AWS, or Azure.',
        'Learn about containerization with Docker and orchestration with Kubernetes.',
        'Understand DevOps principles for continuous integration and deployment (CI/CD) pipelines.'

      ]
    },
    {
      title: 'Resume Preparation',
      points: [
        'Include keywords.',
        'How to prepare resume.',
        'How to prepare resume for freshers.',
        'Show impact.',
        'Include soft skills.',
        'Be unique.'
      ]
    }
  ];
  const syllabus = [
    {
      title: 'Hypher Text Markup Language(HTML5)',
      subpoints: [
        'Introduction HTML',
        'HTML Basics',
        'HTML Elements',
        'HTML5 Semantic',
        'HTML Attributes',
        'HTML Headings',
        'HTML Paragraph',
        'HTML styles',
        'HTML Formatting',
        'HTML Quotations',
        'HTML Computer Code',
        'HTML Comments & Colors',
        'HTML CSS, Links and Images',
        'HTML Lists',
        'HTML Blocks',
        'HTML Classes',
        'HTML Layout',
        'HTML Responsive',
        'HTML iframes',
        'HTML JavaScript',
        'HTML Head',
      ],
    },
    {
      title: 'Cascading style Sheet (CSS)',
      subpoints: [
        'Introduction CSS3',
        'CSS3 syntax',
        'CSS3 How To',
        'CSS3 Colours',
        'CSS3 Backgrounds',
        'CSS3 Boarders',
        'CSS Padding',
        'CSS Height/Width',
        'CSS3 Gradients',
        'CSS3 Shadows',
        'CSS3 Text',
        'CSS3 Fonts',
        'CSS3 2D Transforms',
        'CSS3 3D Transforms',
        'CSS Links',
        'CSS Lists',
        'CSS Tables',
        'CSS Box Model',
        'CSS Outline',
        'CSS Display',
        'CSS Max-width',
        'CSS Position',
        'CSS Float',
        'CSS Inline-block',
        'CSS Align',
        'CSS Combinators',
        'CSS Pseudo-class',
        'CSS Pseudo-element',
        'CSS Navigation Bar',
        'CSS Dropdowns',
        'CSS Tooltips',
        'CSS3 Images',
        'CSS Attr Selectors',
        'CSS Forms',
        'CSS Counters',
        'CSS3 Animations',
        'CSS3 Buttons',
        'CSS3 Pagination',
        'CSS3 Multiple Columns',
        'CSS3 User Interface',
        'CSS3 Box Sizing',
        'CSS3 Filters',
        'CSS3 Media Queries.',
        'CSS3 Responsive',
      ],

      // continue......
    },
    {
      title: 'Bootstrap Module',
      subpoints: [
        'Introduction to Bootstrap',
        'Bootstrap Basics',
        'Bootstrap Grids',
        'Bootstrap Themes',
        'Bootstrap CSS',
        'Bootstrap JS',
      ],
    },
    {
      title: 'Javascript Module',
      subpoints: [
        'Introduction to JavaScript',
        'JavaScript Language Basics',
        'JavaScript Objects',
        'JavaScript Scope',
        'JavaScript Events',
        'JavaScript Strings',
        'JavaScript Numbers',
        'JavaScript Math',
        'JavaScript Arrays',
        'JavaScript Boolean',
        'JavaScript Comparisons',
        'JavaScript Conditions',
        'JavaScript Switch',
        'JavaScript Loops',
        'JavaScript Type Conversion',
        'JavaScript RegExp',
        'JavaScript Errors',
        'JavaScript Debugging',
        'JavaScript Hoisting',
        'JavaScript Strict Mode',
        'JavaScript Functions',
        'JavaScript Objects',
        'JavaScript Forms',
        'JavaScript HTML DOM',
        'JavaScript BOM',
      ],
    },
    {
      title: 'jQuery Module Objectives',
      subpoints: [
        'Introduction to jQuery',
        'jQuery Syntax',
        'jQuery Selectors',
        'jQuery Events',
        'jQuery Effects',
        'jQuery HTML',
        'jQuery Traversing',
        'jQuery AJAX & Misc',
      ],
    },
    {
      title: 'SQL Module',
      subpoints: [
        'Relational Database Concepts',
        'How to Design Your Database & Web Database Architecture',
        'Creating your web database',
        'Creating Databases and Users',
        'Introduction to MySQL Privilege System',
        'Setting Up a User for the Web',
        'Creating Database Tables',
        'Working with your MySQL database',
        'Inserting, Retrieving and Updating Data in to the Database',
        'Altering Tables after Creation',
        'Accessing your MySQL Database from the web with nodejs',
        'How Web Database Architectures Work',
        'Checking and Filtering Input Data',
        'Setting Up a connection & Querying the Database',
      ],
    },

    {
      title: 'ReactJS(Building Web App using RecatJS)(Module)',

    },

    {
      title: 'Nodejs',
      subpoints: [
        'Introduction to Nodejs',
        'Architecture of Nodejs Application',
        'Synchronous and Asynchronous Programming',
        'Call back Function in nodejs',
        'Promises in Nodejs',
        'Mongodb with Nodejs',
        'Design the Schema in Nodejs',
        'Design the Rest API’s',
        'GET,POST,PUT,DELETE',
        'JSON web Token Authentication in nodejs',
        'Create the Auth APP in nodejs',
        'Create the E-commerce Backend',
        'Integrated Payment Gateway',
      ],
    },
    {
      title: 'EXPRESSJS (Building RESTFUL APIs Using Express)',
      subpoints: [
        'Express',
        'Restful services',
        'Introducing Express',
        'Building your First Web Server',
        'Nodemon',
        'Environment Variables',
        'Route Parameters',
        'Handling HTTP GET Request',
        'Handling HTTP POST Request',
        'Calling Endpoints Using Postman',
        'Input Validations',
        'Handling HTTP PUT Request',
        'Handling HTTP DELETE Request',
        'Project- Build the Genres API',
      ],
    },
    {
      title: 'Express-Advanced Topics',
      subpoints: [
        'Middleware',
        'Creating a Custom Middleware',
        'Built-in Middleware',
        'Environments',
        'Configuration',
        'Debugging',
        'Templating Engine',
        'Database Engines',
        'Database Integration',
        'Authentication',
        'Structuring Express Applications',
      ],
    },
    {
      title: 'MongoDB',
      subpoints: [
        'Introduction to MongoDB (No-sql)',
        'Collections in MongoDb',
        'Documents In mongoDb',
        'Difference between Mysql and NoSql',
        'Inserting data into database',
        'Filter queries in Mongodb Database',
        'Schema Validation in MongoDb database',
        'Indexing In collections',
        'Aggregation in MongoDb',
        'Embedded Document in MongoDb ',
      ],
    },
    {
      title: 'MERN Stack Course Outline  ReactJs',
      subpoints: [
        'Introduction to ReactJS',
        'Understand ReactJSLibrary & directory',
        'React Components',
        'Types of Components',
        'Build a simple React component',
        'Component composition',
        'Component styling',
        'Add styles to your components',
        'How to pass data from one component to another',
        'Routing',
        'Using Routing to create single page app',
        'Hooks • States',
        'Hooks vs States',
        'Types of Hooks',
        'Redux',
        'Using Redux as state container for react apps',
        'React Bootstrap',
        'How to deploy ReactJS App Nodejs',
        'Introduction to Nodejs',
        'Architecture of Nodejs Application',
        'Synchronous and Asynchronous Programming',
        'Call back Function in nodejs',
        'Promises in Nodejs',
        'Mongodb with Nodejs',
        'Design the Schema in Nodejs',
        'Design the Rest API’s',
        'GET, POST, PUT, DELETE',
        'JSON web Token Authentication in nodejs',
        'Create the Auth APP in nodejs',
        'Create the E-commerce Backend',
        'Integrated Payment Gateway',
      ],
    },

  ];

  const keyFeatures = [
    { text: "Limited Students Batch", icon: "fa-users" },
    { text: "Personalised Attention", icon: "fa-user-check" },
    { text: "Highly Qualified Teachers", icon: "fa-chalkboard-teacher" },
    { text: "Flexible Batch Timings", icon: "fa-calendar-alt" },
    { text: "Interactive Learning", icon: "fa-comments" },
    { text: "Live Projects", icon: "fa-laptop-code" },
    { text: "Career Support", icon: "fa-briefcase" },
    { text: "Job Oriented Training", icon: "fa-graduation-cap" },
  ];
  const faqs = [
    {
      question: "What is Full Stack developer?",
      answer: "This question aims to understand the role and responsibilities of a Full stack developer who works on both front-end and back-end technologies.",
    },
    {
      question: "What technologies do Full stack developers use?",
      answer: "People often search for the specific programming languages, frameworks, databases, and tools commonly used by Full stack developers.",
    },
    {
      question: "How to become a Full Stack developer?",
      answer: "This question covers the educational background, skills, and steps required to transition into a career as a Full stack developer.",
    },
    {
      question: "What are the key skills of a Full Stack developer?",
      answer: "Users want to know the essential skills such as HTML/CSS, JavaScript, Node.js, React, database management, version control, and deployment.",
    },
    {
      question: "What is the salary of a Full Stack developer?",
      answer: "This question is about the average salary range for Full stack developers based on experience, location, and industry.",
    },
    {
      question: "What are some Full stack developer interview questions?",
      answer: "People seek common interview questions to prepare for Full stack developer roles, covering technical, problem-solving, and behavioral aspects.",
    },
    {
      question: "Is it necessary to know both front-end and back-end for Full stack developement?",
      answer: "This question addresses the level of proficiency required in front-end and back-end technologies for Full stack developers.",
    },
    {
      question: "What are the latest trends in Full stack development?",
      answer: "Users are interested in staying updated with emerging technologies, frameworks, and best practices in the Full stack development domain.",
    },
    {
      question: "How to build a Full stack developer portfolio?",
      answer: "This question focuses on creating a strong portfolio showcasing projects, skills, and achievements relevant to Full stack development.",
    },

  ];

  const whatsData = [
    {
      title: 'What is Full Stack Developer?',
      points: [
        'Full stack development involves working on both front-end and back-end technologies for web applications.',
        'IT Accurate’s course covers HTML/CSS, JavaScript, React, Node.js/Python, SQL/NoSQL, and deployment.',
        'The course is available online and offline, with 100% placement assistance.',
      ]
    }
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="devops-page">
      <div className="content-container-devops">
        <div className="devops-circle devops-circle-bottom-left"></div>

        {/* Left Content */}
        <div className="left-content">
          <h1>IT Accurate - Best Full Stack Developer Training in Mumbai, Thane & Navi Mumbai - Fees, Placements</h1>
          <div className="stars-section">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon
                key={index}
                icon={faStar}
                className="star-icon"
              />
            ))}
          </div>
          <div className="ratings-info">
            <span className="rating">(4.7)</span>
            <span className="separator">|</span>
            <span className="ratings-count">5489 Ratings</span>
            <span className="separator">|</span>
            <span className="learners-count">6067 Learners</span>
          </div>
          <p>
            IT Accurate offers the best Full Stack Developer training in Thane for both freshers and experienced candidates, with expert guidance and 100% guaranteed placement assistance. Our Full Stack Developer training course covers all technical topics in detail, ensuring that students gain real-time hands-on experience.
          </p>
          <p>
            Our practical, job-oriented training program will not only provide you with a certificate but also with knowledge equivalent to 5+ years of field experience. We value your time as much as ours, so we’ve designed the program to train you in Full Stack Development in just 4 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-month internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
          </ul>
        </div>


        {/* Right Side - Contact Form */}
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>

      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div>
      <StickyNavbar name="FullStack" />
      <WhatsDevOps whatsData={whatsData} />
      <SalaryHikeSection />
      <BatchesPage Batches={BatchesPage} />
      <Learn course="development" />
      <Roadmap heading="Roadmap to Learn Full stack developer" roadmapData={roadmapData} />
      <JobPreparation />
      <KeyFeatures heading="Full stack developer Training" keyFeatures={keyFeatures} />

      <Syllabus heading="Full stack developer Training Syllabus" Syllabus={syllabus} />



      <section id="certification" className="devops-certification-section">
        <h2 className="certification-heading">Full stack developer Certification</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>IT Accurate training certification will serve as proof that the courses were completed by IT Accurate.</li>
              <li>The Full Stack Developer certification offered by IT Accurate will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>IT Accurate provides comprehensive guidance for your Full Stack Developer global certification, ensuring a 100% passing guarantee in examinations such as Full Stack Developer Certification.</li>
              <li>Full Stack Developer Platform Development Certification, and various other global exams.</li>
            </ul>

          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">Devops Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}

      <Marq />
      <CourseOpportunities pageName="FullStackDevelopment" />
      <Faq Faqs={faqs} />
      <MarqueeLogos />
    </div>
  );
};

export default AIML;
